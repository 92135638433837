import ReactDOM from "react-dom";
import {BundleEntry} from "./components/bundle-entry/BundleEntry";
import React from "react";
import {getBlLocaleFromString} from "./lib/BlLocale";
import HomeTopOfPage from "./components/home-top-of-page/HomeTopOfPage";

const target = document.getElementById('home-top-of-page');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);
const isAnonymous = target?.dataset?.['isAnonymous'];

if (target) {
	ReactDOM.render(
		<BundleEntry
			bundleName="home-top-of-page"
			locale={localeCode}
			prefetchTranslations={[
				'frontend.home.topOfPage'
			]}>

			<HomeTopOfPage
				isAnonymous={isAnonymous === 'true'} />

		</BundleEntry>,
		document.getElementById('home-top-of-page')
	);
}
