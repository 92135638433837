import {FormattedMessage} from "react-intl";
import ImgArrowUp from "./ArrowUp.png";
import {useEffect} from "react";
import {favoriteActions} from "../favorites/favoriteListSlice";
import {useAppDispatch} from "../../lib/redux/reduxHooks";

interface HomeTopOfPageProps {
	isAnonymous: boolean
}

export default function HomeTopOfPage(props: HomeTopOfPageProps) {

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(favoriteActions.fetchFavoriteProductList(
			props.isAnonymous
		));
	}, [dispatch, props.isAnonymous]);

	return (
		<div id={"home-top-of-page-items"} className={"tw-w-full tw-mt-14"}>
			<div
				className={"tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-black  hover:tw-text-grey"}>
				<button
					className={"tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center"}
					onClick={() => {
						window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
					}}>

					<img className={"tw-w-homeTopOfPageImageMobileWidth"}
						 src={ImgArrowUp}
						 alt={"ArrowUp"}/>

					<div
						className={"tw-uppercase tw-font-gotham tw-text-homeTopOfPageTextSize tw-mt-3"}>
						<FormattedMessage id={'frontend.home.topOfPage'}/>
					</div>

				</button>

			</div>

		</div>
	);
}